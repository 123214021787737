.react-calendar__tile--range {
    font-weight: 1000;
    color: white;
    border-radius: 0;
    background-color: #71CC98;
}

.react-calendar__tile--now {
    /*background: #6f48eb33;*/
    border-radius: 100%;
    font-weight: bold;
    /*color: #6f48eb;*/
    /*color: white;*/
}

.react-calendar__tile {
    cursor: pointer;
}

.react-calendar__tile:hover {
    background-color: rgba(113, 204, 152, 0.3);
    color: white;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__navigation {
    margin: 1em;
}

.react-calendar__navigation__label {
    border: none;
    background: none;
    font-family: 'Futura Light';
    font-size: 1.25em;
    color: rgba(0, 0, 0, 0.87);
    width: 80%;
}

.react-calendar__navigation__arrow {
    border: none;
    background: none;
    font-family: 'Futura Light';
    font-size: 1.8em;
    cursor: pointer;
}